@font-face {
  font-family: 'KONE Information';
  src: local('KONE Information'), url(./fonts/KONE-Information.ttf) format('truetype');
}

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5em;
    color: #1a202c; 
    font-weight: 500; 
  }

  .ant-steps-item .ant-steps-item-title::after {
    background-color: #f0f0f0 !important;
  }

.ant-steps-item-wait .ant-steps-item-icon {
    background-color: #fff !important;
    border-color: #aaafb3 !important;
}

.ant-steps {
  padding-top: 1.3rem
}

.ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, .85) !important;
  font-size: 16px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.ant-select-dropdown {
  color: rgba(0, 0, 0, .85) !important;
  background-color: #fff !important;
}


.ant-select-item {
  color: rgba(0, 0, 0, .85) !important;
  cursor: pointer;
  transition: background .3s ease;
}

.ant-select-selector {
  position: relative;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all .3s cubic-bezier(.645,.045,.355,1);
}

.ant-select-outlined.ant-select-disabled:not(.ant-select-customize-input) .ant-select-selector {
  background: #566066;
  color: #AAAFB3 !important;
}

.ant-picker {
  color: rgba(0,0,0,.85) !important;
}

.ant-picker.ant-picker-disabled {
  background: #566066 !important;
  border-color: #d9d9d9 !important;
}

.ant-picker.ant-picker-disabled input::placeholder {
  color: #d9d9d9 !important;
}

.ant-select-single .ant-select-selector {
  color: rgba(0,0,0,.85) !important;
}

.ant-switch {
  background-color: #aaafb3 !important;
}

.ant-switch.ant-switch-checked {
  background-color: rgb(255, 255, 255) !important;
}

.ant-picker-panel-container {
  background: #fff !important
}

.ant-picker-cell-in-view {
  color: rgba(0, 0, 0, .85) !important;
}

.ant-picker-header {
  color: rgba(0,0,0,.85) !important;
  border-bottom: 1px solid #f0f0f0 !important;
}

.ant-picker-header-view {
  font-weight: 500 !important;
}

.ant-picker-footer {
  border-top: 1px solid #f0f0f0 !important;
}

.ant-modal {
  font-family: "KONE Information",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

.ant-modal-header {
  background: rgb(61, 70, 76) !important;
  padding: 16px 32px !important;
  color: rgba(0,0,0,.85) !important;
  border-bottom: unset !important;
    border-radius: unset !important;
}

.ant-modal .ant-modal-title { 
  line-height: 22px !important;
  font-weight: 500 !important;
}

.ant-modal .ant-modal-content {
  padding: 0 !important;
}

.ant-modal-content {
  background-color: rgb(17, 22, 26) !important;
  border-radius: 4px !important;
  box-shadow: 0 3px 6px -4px rgba(0,0,0,.12),0 6px 16px 0 rgba(0,0,0,.08),0 9px 28px 8px rgba(0,0,0,.05) !important;
}

.ant-modal-body {
  color: rgb(198, 202, 204) !important;
  font-size: 24px !important;
  text-align: center !important;
  padding: 24px 56px 0px !important;
}

.ant-modal .ant-modal-footer {
  padding: 20px !important;
}

.ant-dropdown-menu-item {
  color: rgb(212, 215, 217) !important;
  padding: 15px 12px !important;
}

.ant-dropdown .ant-dropdown-menu {
  position: absolute !important;
  top: 35px !important;
  right: 0 !important;
}

.ant-tabs {
  font-family: "KONE Information",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
}

.ant-tabs .ant-tabs-tab {
  color: #c6cacc !important;
  font-size: 16px !important;
}

.ant-checkbox-wrapper {
  color: rgba(0,0,0,.85) !important;
}

.ant-input::placeholder {
  color: rgb(156, 153, 153) !important;
  font-weight: 500;
}

/* .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  color: rgb(198, 202, 204) !important;
} */

.ant-select .ant-select-arrow {
  color: #aaafb3 !important;
}

/* .ant-switch.ant-switch-checked {
  background-color: rgb(0, 113, 185) !important;
} */

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  font-family: "KONE Information",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  background-color: #11161a
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

